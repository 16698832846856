import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

const conicGradient = `
conic-gradient(
  at -10% 80%,
  ${Color.Primitive.Secondary}50 1%,
  ${Color.Primitive.Primary}30 22%,
  transparent 25%
)
`;

export const Colors = {
  ...Colors_default,

  ProcessCardBackground: Color.Surface.Base.Background,
  ProcessCardProgress: Color.Primitive.Primary, // The animated border

  ProgressBarGlow: `0 0 4px 0px ${Color.Primitive.Primary}60`,
  ProgressBarValue: 'linear-gradient(180deg, #FFFFFF 1%, #F6E6FE 5%, #BE86D9)',

  ProgressMaintainCompleted: Color.Primitive.Primary,
  ProgressMaintainCompletedText: Color.BodyText,
  ProgressMaintainCompletedGlow: `0 0 6px 2px ${Color.Primitive.Primary}80`,

  ProgressSectionBackground: conicGradient,

  WidgetBackgroundImage: conicGradient,
};
